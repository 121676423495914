<div class="flex flex-col min-h-[200px] h-auto max-h-[90vh] bg-gray-50">

  <!-- Header -->
  <header class="sticky top-0 z-50 bg-white border-b border-gray-100">
    <div class="container mx-auto px-4 py-4">
      <div class="flex items-center justify-between">
        <!-- Lado esquerdo com info do bot -->
        <div class="flex items-center gap-4">
          <div class="relative">
            <div>
              <svg width="62" height="62" viewBox="0 0 109 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="9.54739" y="0.162659" width="99.9898" height="91.3377" rx="17"
                  transform="rotate(6 9.54739 0.162659)" fill="#2563EB" />
                <path
                  d="M48.2653 21.9022C49.851 15.366 59.1491 15.366 60.7348 21.9022C60.9727 22.8841 61.4391 23.796 62.096 24.5636C62.7529 25.3312 63.5818 25.9328 64.5152 26.3195C65.4486 26.7063 66.4602 26.8671 67.4675 26.789C68.4748 26.7109 69.4494 26.3961 70.312 25.8701C76.0554 22.3712 82.6326 28.9447 79.1337 34.6918C78.6085 35.554 78.2942 36.5281 78.2162 37.5347C78.1383 38.5413 78.299 39.5521 78.6853 40.485C79.0715 41.4178 79.6725 42.2463 80.4392 42.9032C81.2059 43.5601 82.1168 44.0267 83.0979 44.2653C89.6341 45.851 89.6341 55.1491 83.0979 56.7348C82.116 56.9727 81.2041 57.4391 80.4365 58.096C79.6689 58.753 79.0672 59.5819 78.6805 60.5152C78.2938 61.4486 78.133 62.4602 78.2111 63.4675C78.2892 64.4748 78.604 65.4494 79.13 66.312C82.6289 72.0554 76.0554 78.6326 70.3083 75.1337C69.446 74.6085 68.472 74.2942 67.4654 74.2162C66.4588 74.1383 65.448 74.299 64.5151 74.6853C63.5823 75.0715 62.7538 75.6725 62.0969 76.4392C61.44 77.2059 60.9733 78.1168 60.7348 79.0979C59.1491 85.6341 49.851 85.6341 48.2653 79.0979C48.0274 78.116 47.561 77.2041 46.9041 76.4365C46.2471 75.6689 45.4182 75.0673 44.4848 74.6805C43.5514 74.2938 42.5399 74.133 41.5326 74.2111C40.5253 74.2892 39.5507 74.604 38.688 75.13C32.9446 78.6289 26.3675 72.0554 29.8664 66.3083C30.3916 65.446 30.7059 64.472 30.7838 63.4654C30.8618 62.4588 30.7011 61.448 30.3148 60.5151C29.9285 59.5823 29.3276 58.7538 28.5609 58.0969C27.7942 57.44 26.8832 56.9733 25.9022 56.7348C19.366 55.1491 19.366 45.851 25.9022 44.2653C26.8841 44.0274 27.796 43.561 28.5636 42.9041C29.3312 42.2471 29.9328 41.4182 30.3195 40.4848C30.7062 39.5514 30.8671 38.5399 30.789 37.5326C30.7109 36.5253 30.3961 35.5507 29.8701 34.688C26.3712 28.9447 32.9446 22.3675 38.6918 25.8664C42.3991 28.1295 47.238 26.1269 48.2653 21.9022Z"
                  stroke="white" stroke-width="9" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <span class="absolute bottom-0 right-0 block h-3 w-3 rounded-full bg-green-500 ring-2 ring-white"></span>
          </div>

          <div class="flex flex-col">
            <h1 class="text-lg font-bold text-gray-800 leading-tight">Assistente CuponAI</h1>
            <p class="text-sm text-gray-500">Encontre as melhores ofertas</p>
          </div>
        </div>

        <!-- Lado direito com ações -->
        <div class="flex items-center gap-2">
          <button (click)="navigateToProductsList()"
            class="p-2 hover:bg-gray-50 rounded-xl transition-colors duration-200"
            aria-label="Navegar para lista de produtos">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
            </svg>
          </button>

          <button (click)="clearChat()" class="p-2 hover:bg-gray-50 rounded-xl transition-colors duration-200"
            aria-label="Limpar conversa">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </header>

  <!-- Chat Container -->
  <main class="flex-1 overflow-hidden flex flex-col container mx-auto px-4 md:px-6 lg:px-8">
    <div class="chat-scroll-container">
      <!-- Empty State -->
      <section *ngIf="messages.length === 0" class="h-full flex flex-col justify-center items-center text-center py-12">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 text-primary mb-4" fill="none" viewBox="0 0 24 24"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <h2 class="text-2xl font-semibold text-gray-700 mb-2">Bem-vindo</h2>
        <p class="text-gray-500 max-w-md">Como posso ajudar você hoje? Estou aqui para responder suas perguntas e
          encontrar as melhores ofertas.</p>
      </section>

      <!-- Chat Messages -->
      <section #chatContainer *ngIf="messages.length > 0" class="messages-container py-4 space-y-4">
        <div *ngFor="let message of messages; trackBy: trackByMessages" class="flex w-full"
          [ngClass]="message.sender === 'user' ? 'justify-end' : 'justify-start'">
          <div class="max-w-[90%] md:max-w-[70%]">
            <div class="px-4 py-3 rounded-xl shadow-md transition-all text-sm md:text-base"
              style="white-space: pre-line;" [ngClass]="message.sender === 'user' ? 
                            'bg-primary text-white' : 
                            message.isError ? 'bg-red-50 text-red-700 border border-red-100' :
                            'bg-white text-gray-800 border border-gray-100'">

              <!-- Message Content -->
              <div class="markdown-content">
                <!-- Usar [innerHTML] com pipe de sanitização para preservar formatação -->
                <span [innerHTML]="message.text | safeHtml"
                  [ngClass]="{'typing-effect': isLoading && messages.indexOf(message) === messages.length - 1 && message.sender === 'assistant'}">
                </span>
              </div>
              <!-- Products Section - Solução Final -->
              <!-- Indicador de carregamento (aparece apenas quando estamos carregando e não há produtos visíveis) -->
              <div *ngIf="message.loadingProducts && (!message.visibleProducts || message.visibleProducts.length === 0)"
                class="mt-3 py-2 px-3 bg-gray-50 rounded-lg border border-gray-100 inline-flex items-center text-sm text-gray-600">
                <div class="flex space-x-2 mr-2">
                  <div class="h-2 w-2 bg-primary rounded-full animate-bounce" style="animation-delay: 0ms"></div>
                  <div class="h-2 w-2 bg-primary rounded-full animate-bounce" style="animation-delay: 150ms"></div>
                  <div class="h-2 w-2 bg-primary rounded-full animate-bounce" style="animation-delay: 300ms"></div>
                </div>
                Buscando ofertas...
              </div>
              <!-- Versão colapsada das ofertas -->
              <div *ngIf="message.products && message.products.length > 0 && message.isCollapsed"
                class="mt-3 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-xl border border-blue-100 p-3 cursor-pointer hover:bg-blue-100 transition-colors shine-effect"
                [ngClass]="{'pulse-attention': message.showAttentionAnimation}"
                (click)="toggleOfferCollapse(messages.indexOf(message))">
                <div class="flex items-center justify-between">
                  <div class="flex items-center gap-2">
                    <div class="bg-primary rounded-full p-1.5 shadow-md">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                          d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z"
                          clip-rule="evenodd" />
                        <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z" />
                      </svg>
                    </div>
                    <div>
                      <p class="font-medium text-gray-800">{{message.productCount}} ofertas encontradas</p>
                      <p class="text-xs text-gray-500">Clique para ver as ofertas</p>
                    </div>
                  </div>
                  <div class="flex items-center">
                    <span class="text-xs text-blue-500 mr-2 animate-pulse">Clique para ver</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-primary" viewBox="0 0 20 20"
                      fill="currentColor">
                      <path fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>

              <!-- Versão expandida das ofertas (modificada) -->
              <ng-container
                *ngIf="message.visibleProducts && message.visibleProducts.length > 0 && !message.isCollapsed">
                <div class="mt-3 space-y-3">
                  <!-- Botão para colapsar a lista quando expandida (topo) -->
                  <div class="flex justify-between items-center mb-2 bg-gray-50 p-2 rounded-lg">
                    <div class="text-sm font-medium text-gray-700">
                      {{message.productCount}} ofertas encontradas
                    </div>
                    <button (click)="toggleOfferCollapse(messages.indexOf(message))"
                      class="text-xs text-gray-500 flex items-center gap-1 hover:text-gray-700 transition-colors">
                      <span>Colapsar lista</span>
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clip-rule="evenodd" />
                      </svg>
                    </button>
                  </div>

<!-- Modifique o card de produto no chat.component.html -->
<div *ngFor="let product of message.visibleProducts; trackBy: trackByProducts; let i = index" 
     class="bg-white rounded-xl shadow-soft overflow-hidden 
     transform transition-default hover:-translate-y-1 
     border border-gray-100 flex h-28 sm:h-32" 
     [ngClass]="{'animate-fade-in': true}"
     [style.animation-delay]="i * 0 + 'ms'">
  
  <!-- Área clicável para abrir o modal (toda a área exceto o botão) -->
  <div class="flex flex-1 cursor-pointer" (click)="openProductDetails(product)">
    <!-- Imagem do Produto -->
    <div class="relative w-24 sm:w-32 flex-shrink-0">
      <img [src]="product.thumbnail" [alt]="product.name" class="w-full h-full object-cover" />
      <div *ngIf="product.discount > 0 && product.price_from && product.price_from > product.price"
        class="absolute top-1 left-1 bg-accent text-white px-1.5 py-0.5 sm:px-2 sm:py-1 rounded-lg text-xs font-semibold">
        -{{product.discount}}%
      </div>
    </div>

    <!-- Informações do Produto -->
    <div class="flex-1 p-2 sm:p-3 flex flex-col justify-between min-w-0">
      <div>
        <h3 class="font-bold text-xs sm:text-sm text-text truncate max-w-full">{{product.name}}</h3>
        <div
          class="flex items-center gap-1 sm:gap-2 mt-0.5 sm:mt-1 cursor-pointer hover:opacity-80 transition-opacity"
           role="button"
          [attr.aria-label]="'Visitar loja ' + product.store.name">
          <img [src]="product.store.thumbnail" [alt]="product.store.name"
            class="w-3 h-3 sm:w-4 sm:h-4 rounded-full" />
          <span class="text-[10px] sm:text-xs text-text-muted truncate">{{product.store.name}}</span>
        </div>
      </div>

      <!-- Preços -->
      <div class="flex items-center justify-between">
        <div class="flex flex-col min-w-0">
          <span *ngIf="product.discount > 0 && product.price_from && product.price_from > product.price"
            class="text-[10px] sm:text-xs text-text-muted line-through truncate">
            {{formatPrice(product.price_from)}}
          </span>
          <span class="text-xs sm:text-sm font-bold text-primary">
            {{formatPrice(product.price)}}
          </span>
          <span *ngIf="product.installment" class="text-[10px] sm:text-xs text-text-muted truncate">
            {{product.installment.quantity}}x de {{formatPrice(product.installment.value)}}
          </span>
        </div>
      </div>
    </div>
  </div>

  <!-- Botão Ver (mantém a função original) -->
  <div class="flex items-center pr-2 sm:pr-3">
    <button (click)="openProductLink(product); $event.stopPropagation();" 
            class="bg-primary hover:bg-primary-dark text-white 
             px-2 sm:px-4 py-1.5 sm:py-2 
             rounded-lg text-xs sm:text-sm font-medium 
             transition-default flex items-center gap-1">
      <span class="hidden sm:inline">Ver</span>
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20"
        fill="currentColor">
        <path fill-rule="evenodd"
          d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
          clip-rule="evenodd" />
      </svg>
    </button>
  </div>
</div>


                  <!-- Botão para colapsar a lista no final quando expandida -->
                  <div class="flex justify-end mt-2">
                    <button (click)="toggleOfferCollapse(messages.indexOf(message))" class="text-xs text-gray-500 flex items-center gap-1 hover:text-gray-700 transition-colors
                     bg-gray-50 px-3 py-2 rounded-lg">
                      <span>Colapsar lista</span>
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </div>
              </ng-container>

            </div>
            <div class="text-xs text-gray-500 mt-1.5 px-1"
              [ngClass]="message.sender === 'user' ? 'text-right' : 'text-left'">
              {{ message.sender === 'user' ? 'Você' : 'CuponAI' }}
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- Input Area -->
    <footer class="sticky bottom-0 border-t border-gray-100 py-3 md:py-4">
      <div class="container mx-auto px-4 md:px-0">
        <div class="flex items-center space-x-2">
          <input #messageInput [(ngModel)]="newMessage" (keyup.enter)="sendMessage()" [disabled]="isLoading"
            [placeholder]="placeholderText" aria-label="Mensagem" class="flex-1 px-4 py-2.5 md:py-3 border border-gray-200 rounded-xl 
                   focus:ring-2 focus:ring-primary focus:outline-none 
                   bg-gray-50 text-gray-800 
                   placeholder-gray-500 text-sm md:text-base
                   transition-all duration-300" />
          <button (click)="sendMessage()" [disabled]="isLoading || !newMessage.trim()" aria-label="Enviar mensagem"
            class="bg-primary text-white px-4 md:px-6 py-2.5 md:py-3 rounded-xl 
                   hover:bg-primary-dark focus:outline-none focus:ring-2 
                   focus:ring-primary focus:ring-offset-2
                   transition-all duration-300 
                   flex items-center gap-2 
                   disabled:opacity-50 disabled:cursor-not-allowed
                   transform hover:scale-105">
            <span class="hidden md:inline">Enviar</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
            </svg>
          </button>
        </div>
      </div>
    </footer>
  </main>
</div>
<!-- Modal de Detalhes do Produto -->

<div *ngIf="showProductModal && selectedProduct" 
     class="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4 animate-fade-in"
     (click)="closeProductModal()">
  
  <!-- Conteúdo do Modal (clique não propaga para fechar) -->
  <div class="bg-white rounded-xl shadow-xl max-w-lg w-full max-h-[90vh] overflow-y-auto" 
       (click)="$event.stopPropagation()">
    
    <!-- Cabeçalho do Modal -->
    <div class="relative">
      <img [src]="selectedProduct.thumbnail" [alt]="selectedProduct.name" 
           class="w-full h-48 object-cover rounded-t-xl">
      
      <!-- Badge de desconto -->
      <div *ngIf="selectedProduct.discount > 0"
           class="absolute top-3 left-3 bg-accent text-white px-3 py-1 rounded-lg text-sm font-bold">
        -{{selectedProduct.discount}}%
      </div>
      
      <!-- Botão de fechar -->
      <button (click)="closeProductModal()" 
              class="absolute top-3 right-3 bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-700" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>
    
    <!-- Corpo do Modal -->
    <div class="p-5">
      <!-- Informações da Loja -->
      <div class="flex items-center mb-4 cursor-pointer hover:opacity-80 transition-opacity"
           (click)="openStoreLink(selectedProduct.store.link)">
        <img [src]="selectedProduct.store.thumbnail" [alt]="selectedProduct.store.name"
             class="w-6 h-6 rounded-full mr-2">
        <span class="text-sm text-gray-600">{{selectedProduct.store.name}}</span>
      </div>
      
      <!-- Nome do Produto -->
      <h2 class="text-xl font-bold text-gray-800 mb-3">{{selectedProduct.name}}</h2>
      
      <!-- Categoria -->
      <div class="mb-4">
        <span class="text-xs bg-blue-50 text-blue-600 px-2 py-1 rounded-full">
          {{selectedProduct.category.name}}
        </span>
      </div>
      
      <!-- Preços -->
      <div class="flex items-end gap-2 mb-4">
        <span *ngIf="selectedProduct.discount > 0" class="text-sm text-gray-500 line-through">
          {{formatPrice(selectedProduct.price_from || selectedProduct.price)}}
        </span>
        <span class="text-2xl font-bold text-primary">
          {{formatPrice(selectedProduct.price)}}
        </span>
      </div>
      
      <!-- Parcelamento -->
      <div *ngIf="selectedProduct.installment" class="bg-gray-50 p-3 rounded-lg mb-4">
        <p class="text-sm text-gray-700">
          <span class="font-medium">Parcelamento:</span> 
          {{selectedProduct.installment.quantity}}x de {{formatPrice(selectedProduct.installment.value)}}
        </p>
      </div>
      
      <!-- Informações Adicionais -->
      <div class="border-t border-gray-100 pt-4 mb-4">
        <h3 class="text-sm font-medium text-gray-700 mb-2">Informações adicionais:</h3>
        <ul class="text-sm text-gray-600 space-y-1">
          <li class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-green-500" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
            <span>Categoria: {{selectedProduct.category.name}}</span>
          </li>
          <li class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-green-500" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
            <span>Vendido por: {{selectedProduct.store.name}}</span>
          </li>
          <li *ngIf="selectedProduct.discount > 0" class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-green-500" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
            <span>Economia de {{formatPrice((selectedProduct.price_from || selectedProduct.price) - selectedProduct.price)}}</span>
          </li>
        </ul>
      </div>
    </div>
    
    <!-- Rodapé do Modal -->
    <div class="border-t border-gray-100 p-4 flex justify-between items-center bg-gray-50 rounded-b-xl">
      <button (click)="closeProductModal()" 
              class="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-100 transition-colors">
        Voltar
      </button>
      <button (click)="openProductLink(selectedProduct)" 
              class="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors flex items-center gap-2">
        <span>Ver oferta</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>
  </div>
</div>


