import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, ViewChild, OnInit, DestroyRef, inject, PLATFORM_ID, OnDestroy, AfterViewInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Message } from '../../models/message.model';
import { Offer } from '../../models/offer.model';
import { LoggerService } from '../../services/logger.service';
import { ChatApiService } from '../../services/chat-api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize, takeUntil } from 'rxjs/operators';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { v4 as uuidv4 } from 'uuid';

// Interface simplificada para mensagens de chat
interface ChatMessage extends Message {
  products?: Offer[];
  visibleProducts?: Offer[]; // Produtos visíveis
  isError?: boolean;
  loadingProducts?: boolean; // Nova propriedade para indicar carregamento
  isCollapsed?: boolean; // Nova propriedade para controlar o estado de colapso
  productCount?: number; // Número de produtos na lista para exibir no resumo
  showAttentionAnimation?: boolean; // Nova propriedade para controlar a animação
}

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, SafeHtmlPipe],
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class MobileChatComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('messageInput') messageInput!: ElementRef;
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  private destroyRef = inject(DestroyRef);
  private platformId = inject(PLATFORM_ID);

  messages: ChatMessage[] = [];
  newMessage = '';
  isLoading = false;
  produtos: Offer[] = [];
  sessionId = '';
  private ngUnsubscribe = new Subject<void>();

  // Texto de placeholder para o input de mensagem
  placeholderText = 'Digite sua dúvida sobre ofertas...';

  selectedProduct: Offer | null = null;
  showProductModal: boolean = false;

  constructor(
    private chatApiService: ChatApiService,
    private router: Router,
    private logger: LoggerService
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Gerar ID de sessão único
      this.sessionId = uuidv4();
      localStorage.setItem('chat_session_id', this.sessionId);

      localStorage.removeItem('chat_messages');
      this.messages = [];

      // Configurar observadores do DOM
      this.setupDOMListeners();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): void {
    // Garantir scroll após a renderização inicial
    this.scrollToBottom();
  }

  private setupDOMListeners(): void {
    // Observer para detectar mudanças no chat e fazer scroll
    if (this.chatContainer?.nativeElement) {
      const observer = new MutationObserver(() => this.scrollToBottom());
      observer.observe(this.chatContainer.nativeElement, {
        childList: true,
        subtree: true
      });
    }

    // Listener para redimensionamento da janela
    window.addEventListener('resize', () => this.scrollToBottom());
  }

  private loadChatHistory(): void {
    // Carregar histórico do localStorage se existir
    const savedMessages = localStorage.getItem('chat_messages');
    if (savedMessages) {
      try {
        this.messages = JSON.parse(savedMessages);
        this.scrollToBottom();
      } catch (e) {
        this.logger.error('Erro ao carregar histórico de chat:', e);
      }
    }
  }

  private saveMessages(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('chat_messages', JSON.stringify(this.messages));
    }
  }

  toggleOfferCollapse(index: number): void {
    if (this.messages[index] && this.messages[index].products && this.messages[index].products.length > 0) {
      this.messages[index].isCollapsed = !this.messages[index].isCollapsed;
      this.saveMessages();
      // Se estiver expandindo, garantir que o scroll seja ajustado
      if (!this.messages[index].isCollapsed) {
        setTimeout(() => this.scrollToBottom(), 100);
      }
    }
  }

  private collapseAllPreviousOffers(): void {
    // Colapsar todas as mensagens anteriores que contêm produtos
    for (let i = 0; i < this.messages.length - 1; i++) {
      const message = this.messages[i];
      // Verificar se a mensagem existe e tem produtos
      if (message && message.products && message.products.length > 0) {
        message.isCollapsed = true;
        // Armazenar a contagem de produtos para exibir no resumo
        message.productCount = message.products.length;
      }
    }
    this.saveMessages();
  }
  openOffersModal(index: number): void {
    if (this.messages[index] && this.messages[index].products) {
      // Expandir temporariamente para mostrar as ofertas
      this.messages[index].isCollapsed = false;
      
      // Implementar lógica de modal aqui (pode ser um componente separado)
      // Por enquanto, vamos apenas alternar o estado
      
      this.saveMessages();
      setTimeout(() => this.scrollToBottom(), 100);
    }
  }

  async sendMessage(): Promise<void> {
    const trimmedMessage = this.newMessage.trim();
    if (!trimmedMessage) return;

    // Colapsar ofertas anteriores antes de adicionar nova mensagem
    this.collapseAllPreviousOffers();

    // Adiciona mensagem do usuário
    this.addMessage({
      text: trimmedMessage,
      sender: 'user'
    });

    this.isLoading = true;
    this.newMessage = '';

    // Preparar payload para API
    const payload = {
      messages: this.messages.map(m => ({
        role: m.sender === 'user' ? 'user' : 'assistant',
        content: m.text
      })),
      session_id: this.sessionId
    };

    // Criar uma mensagem vazia do assistente que será incrementada
    this.addMessage({
      sender: 'assistant',
      text: ''  // Começar com texto vazio
    });

    // Índice da mensagem do assistente que estamos atualizando
    const assistantMessageIndex = this.messages.length - 1;

    // Variável para acumular o texto recebido
    let accumulatedText = '';
    // Flag para verificar se é o primeiro chunk
    let isFirstChunk = true;

    this.chatApiService.sendMessageStream(payload)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.isLoading = false;
          this.saveMessages();
          this.focusInput();
        })
      )
      .subscribe({
        next: (response) => {
          // Verificar se a resposta já é um objeto ou se é uma string JSON
          let processedResponse;
          
          try {
            // Se a resposta for uma string, tenta fazer o parse
            if (typeof response === 'string') {
              processedResponse = JSON.parse(response);
            } else {
              // Se já for um objeto, usa diretamente
              processedResponse = response;
            }
            
            if (processedResponse.type === 'text' && (processedResponse.text || processedResponse.content)) {
              // Obter o novo texto do chunk
              let newChunk = processedResponse.text || processedResponse.content;
              
              // IMPORTANTE: Verificar se o primeiro chunk contém a mensagem do usuário
              if (isFirstChunk) {
                isFirstChunk = false;

                // Verificar se o texto começa com a mensagem do usuário
                if (newChunk.toLowerCase().startsWith(trimmedMessage.toLowerCase())) {
                  // Remover a mensagem do usuário do início da resposta
                  newChunk = newChunk.substring(trimmedMessage.length).trim();
                }
              }

              accumulatedText += newChunk;

              // Atualizar a mensagem do assistente com o texto acumulado
              this.messages[assistantMessageIndex].text = accumulatedText;
            }
            else if (processedResponse.type === 'offers' && processedResponse.offers) {
              // Log dos campos relacionados a preço para cada oferta
              console.log('---------- OFERTAS RECEBIDAS DA API ----------');
              processedResponse.offers.forEach((offer: Offer, index: number) => {
                console.log(`Oferta ${index + 1}: "${offer.name}"`);
                console.log({
                  price: offer.price,
                  price_from: offer.price_from,
                  discount: offer.discount,
                  installment: offer.installment ? {
                    quantity: offer.installment.quantity,
                    value: offer.installment.value
                  } : null
                });
                console.log('-----------------------------------');
              });
              
              // Código existente continua abaixo
              this.messages[assistantMessageIndex].products = processedResponse.offers;
              this.messages[assistantMessageIndex].visibleProducts = processedResponse.offers;
              this.messages[assistantMessageIndex].loadingProducts = false;
              this.messages[assistantMessageIndex].isCollapsed = true;
              this.messages[assistantMessageIndex].productCount = processedResponse.offers.length;
              this.produtos = processedResponse.offers;
            }
            else if (processedResponse.type === 'error') {
              // Substituir a mensagem por uma mensagem de erro
              this.messages[assistantMessageIndex] = {
                sender: 'assistant',
                text: processedResponse.text || 'Ocorreu um erro ao processar sua solicitação.',
                isError: true
              };
            }
            else if (processedResponse.type === 'complete') {
              // Finalizar a mensagem (opcional, caso queira fazer algo quando terminar)
              this.isLoading = false;
            }
          } catch (error) {
            console.error('Erro ao processar resposta:', error, response);
            // Tratar como texto simples se não for possível fazer o parse
            accumulatedText += response.toString();
            this.messages[assistantMessageIndex].text = accumulatedText;
          }

          this.scrollToBottom();
        },
        error: (error) => {
          this.logger.error('Erro na comunicação com API:', error);
          // Atualizar a mensagem do assistente com o erro
          if (assistantMessageIndex >= 0 && assistantMessageIndex < this.messages.length) {
            this.messages[assistantMessageIndex] = {
              sender: 'assistant',
              text: 'Desculpe, tive um problema ao processar sua mensagem. Por favor, tente novamente.',
              isError: true
            };
          }
          this.scrollToBottom();
        }
      });
  }

  // Modificar o método carregarProdutosGradualmente para carregar todos os produtos de uma vez
  private carregarProdutosGradualmente(messageIndex: number): void {
    const message = this.messages[messageIndex];
    if (!message || !message.products || message.products.length === 0) {
      if (message) {
        message.loadingProducts = false;
      }
      return;
    }
    
    // Armazenar a contagem de produtos para o resumo colapsado
    message.productCount = message.products.length;
    
    // Definir que a mensagem começa colapsada por padrão
    message.isCollapsed = true;
    
    // Carregar todos os produtos de uma vez (sem delay)
    message.visibleProducts = [...message.products];
    message.loadingProducts = false;
    
    this.saveMessages();
  }

  // Modificar o método processStreamResponse para garantir que as ofertas comecem colapsadas
  private processStreamResponse(response: any, messageIndex: number): void {
    const currentMessage = this.messages[messageIndex];

    if (response.type === 'text') {
      // Atualizar texto da mensagem
      this.messages[messageIndex] = {
        ...currentMessage,
        text: response.text || response.content || ''
      };
    }
    else if (response.type === 'offers' && response.offers) {
      // Adicionar ofertas à mensagem (já colapsadas)
      this.messages[messageIndex] = {
        ...currentMessage,
        products: response.offers,
        visibleProducts: response.offers, // Carregar todos os produtos de uma vez
        isCollapsed: true // Começar colapsado
      };
      this.produtos = response.offers;
    }
    else if (response.type === 'error') {
      // Atualizar mensagem com erro
      this.messages[messageIndex] = {
        ...currentMessage,
        text: response.text || 'Ocorreu um erro ao processar sua solicitação.',
        isError: true
      };
    }

    this.scrollToBottom();
  }

  private addMessage(message: ChatMessage): void {
    this.messages.push(message);
    this.scrollToBottom();
    this.saveMessages();
  }

  private focusInput(): void {
    if (!this.isMobileDevice() && this.messageInput?.nativeElement) {
      setTimeout(() => {
        this.messageInput.nativeElement.focus();
      }, 100);
    }
  }

  private scrollToBottom(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        // Usar o container principal para o scroll
        const scrollContainer = document.querySelector('.chat-scroll-container');
        if (scrollContainer) {
          scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }
      }, 50);
    }
  }

  formatPrice(price: number): string {
    return price.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  }

  private isMobileDevice(): boolean {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  openProductLink(product: Offer): void {
    const url = product.link ||
      `https://www.google.com/search?q=${encodeURIComponent(`${product.name} oferta`)}`;
    window.open(url, '_blank');
  }

  navigateToProductsList(): void {
    // Se necessário, armazenar produtos no localStorage para acesso na página de produtos
    if (this.produtos.length > 0) {
      localStorage.setItem('saved_products', JSON.stringify(this.produtos));
      this.router.navigate(['/products-list']);
    }
  }

  trackByMessages(index: number): number {
    return index;
  }

  trackByProducts(index: number, item: Offer): string | number {
    return item.id || index.toString();
  }

  openStoreLink(url: string): void {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  clearChat(): void {
    this.messages = [];
    this.produtos = [];
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('chat_messages');
    }
  }

  // Método para verificar se está próximo do final do scroll
  private isNearBottom(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const scrollContainer = document.querySelector('.chat-scroll-container');
      if (scrollContainer) {
        const threshold = 150; // pixels
        const position = scrollContainer.scrollTop + scrollContainer.clientHeight;
        const height = scrollContainer.scrollHeight;
        return height - position <= threshold;
      }
    }
    return true;
  }

  // Método para abrir o modal com detalhes do produto
  openProductDetails(product: Offer): void {
    this.selectedProduct = product;
    this.showProductModal = true;
    
    // Prevenir scroll da página enquanto o modal estiver aberto
    if (isPlatformBrowser(this.platformId)) {
      document.body.style.overflow = 'hidden';
    }
  }

  // Método para fechar o modal
  closeProductModal(): void {
    this.showProductModal = false;
    this.selectedProduct = null;
    
    // Restaurar scroll da página
    if (isPlatformBrowser(this.platformId)) {
      document.body.style.overflow = '';
    }
  }
}
