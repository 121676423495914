import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';


interface ChatSessionData {
    sessionId: string;
    messageCount: number;
    firstUse: number;
    lastActivity: number;
}

interface UsageStatus {
    remainingMessages: number;
    shouldShowUpgrade: boolean;
    isBlocked: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ChatSessionManager {
    private readonly COOKIE_NAME = 'chat_session';
    private readonly COOKIE_OPTIONS = {
        path: '/',
        secure: true,
        sameSite: 'Strict',
    } as const;

    // Configurações de limites
    private readonly FREE_MESSAGES_PER_SESSION = 10;
    private readonly DAILY_MESSAGE_LIMIT = 20;
    private readonly SESSION_TIMEOUT = 30 * 60 * 1000; // 30 minutos
    
    private sessionData$ = new BehaviorSubject<ChatSessionData | null>(null);
    private usageStatus$ = new BehaviorSubject<UsageStatus>({
        remainingMessages: this.FREE_MESSAGES_PER_SESSION,
        shouldShowUpgrade: false,
        isBlocked: false
    });

    constructor() {
        if (typeof window !== 'undefined') {
          this.initializeSession();
          this.setupEventListeners();
        } else {
          // Estado inicial para SSR
          this.sessionData$.next(null);

          this.updateUsageStatus({
            sessionId: '',
            messageCount: 0,
            firstUse: 0,
            lastActivity: 0
          });
        }
      }

    private initializeSession(): void {
        const existingSession = this.getSessionFromCookie();
        
        if (!existingSession || this.isSessionExpired(existingSession)) {
            this.createNewSession();
        } else {
            this.sessionData$.next(existingSession);
            this.updateLastActivity(existingSession);
            this.updateUsageStatus(existingSession);
        }
    }

    private createNewSession(): void {
        const newSession: ChatSessionData = {
            sessionId: uuidv4(),
            messageCount: 0,
            firstUse: Date.now(),
            lastActivity: Date.now()
        };

        this.setSessionCookie(newSession);
        this.sessionData$.next(newSession);
        this.updateUsageStatus(newSession);
    }

    private getSessionFromCookie(): ChatSessionData | null {
        try {
            const cookie = document.cookie
                .split('; ')
                .find(row => row.startsWith(this.COOKIE_NAME + '='));

            if (!cookie) return null;

            const sessionData = JSON.parse(decodeURIComponent(
                cookie.split('=')[1]
            ));

            return sessionData;
        } catch {
            return null;
        }
    }

    private setSessionCookie(session: ChatSessionData): void {
        const expires = new Date();
        expires.setHours(23, 59, 59, 999); // Expira no fim do dia

        const cookieValue = encodeURIComponent(JSON.stringify(session));
        document.cookie = `${this.COOKIE_NAME}=${cookieValue}; expires=${expires.toUTCString()}; ${Object.entries(this.COOKIE_OPTIONS).map(([k, v]) => `${k}=${v}`).join('; ')}`;
    }

    private isSessionExpired(session: ChatSessionData): boolean {
        const now = Date.now();
        return (now - session.lastActivity) > this.SESSION_TIMEOUT || 
               !this.isFromToday(session.firstUse);
    }

    private isFromToday(timestamp: number): boolean {
        const today = new Date();
        const date = new Date(timestamp);
        return date.getDate() === today.getDate() &&
               date.getMonth() === today.getMonth() &&
               date.getFullYear() === today.getFullYear();
    }

    private updateLastActivity(session: ChatSessionData): void {
        session.lastActivity = Date.now();
        this.setSessionCookie(session);
        this.sessionData$.next(session);
    }

    private updateUsageStatus(session: ChatSessionData | null): void {
        if (!session) {
          this.usageStatus$.next({
            remainingMessages: this.FREE_MESSAGES_PER_SESSION,
            shouldShowUpgrade: false,
            isBlocked: false
          });
          return;
        }
    
        const remainingMessages = this.DAILY_MESSAGE_LIMIT - session.messageCount;
        const shouldShowUpgrade = session.messageCount >= this.FREE_MESSAGES_PER_SESSION;
        const isBlocked = session.messageCount >= this.DAILY_MESSAGE_LIMIT;
    
        this.usageStatus$.next({
          remainingMessages,
          shouldShowUpgrade,
          isBlocked
        });
      }

    private setupEventListeners(): void {
        // Atualiza atividade em interações
        ['click', 'keypress', 'scroll'].forEach(eventType => {
            document.addEventListener(eventType, () => {
                const currentSession = this.sessionData$.value;
                if (currentSession) {
                    this.updateLastActivity(currentSession);
                }
            });
        });

        // Limpa sessão ao fechar
        window.addEventListener('beforeunload', () => {
            const currentSession = this.sessionData$.value;
            if (currentSession) {
                currentSession.lastActivity = 0; // Força expiração
                this.setSessionCookie(currentSession);
            }
        });

        // Verifica timeout periodicamente
        setInterval(() => {
            const currentSession = this.sessionData$.value;
            if (currentSession && this.isSessionExpired(currentSession)) {
                this.createNewSession();
            }
        }, 60000); // Checa a cada minuto
    }

    // Método público para trackear mensagens
    async trackMessage(): Promise<boolean> {
        const session = this.sessionData$.value;
        if (!session) {
            this.createNewSession();
            return true;
        }

        if (session.messageCount >= this.DAILY_MESSAGE_LIMIT) {
            return false;
        }

        session.messageCount++;
        this.setSessionCookie(session);
        this.sessionData$.next(session);
        this.updateUsageStatus(session);

        return true;
    }

    // Getters públicos
    getSessionId(): string {
        return this.sessionData$.value?.sessionId || '';
    }

    getUsageStatus$() {
        return this.usageStatus$.asObservable();
    }

    getRemainingMessages(): number {
        return this.usageStatus$.value.remainingMessages;
    }

    isSessionActive(): boolean {
        const session = this.sessionData$.value;
        return !!session && !this.isSessionExpired(session);
    }
}