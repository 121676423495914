// import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
// import { provideRouter } from '@angular/router';
// import { routes } from './app.routes';
// import { provideClientHydration, withEventReplay } from '@angular/platform-browser';
// import { provideHttpClient } from '@angular/common/http';
// import { ChatSessionManager } from './services/chat-session.manager';
// import { AiAssistantService } from './services/assistente.service';
// import { ChatApiService } from './services/chat-api.service';

// export const appConfig: ApplicationConfig = {
//   providers: [
//     provideZoneChangeDetection({ eventCoalescing: true }), 
//     provideRouter(routes), 
//     provideClientHydration(withEventReplay()),
//     ChatSessionManager,
//     AiAssistantService,
//     ChatApiService,
//     provideHttpClient(), 
//   ]
// };

import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration, withEventReplay } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { ChatSessionManager } from './services/chat-session.manager';
import { AiAssistantService } from './services/assistente.service';
import { ChatApiService } from './services/chat-api.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ 
      eventCoalescing: true 
    }), 
    provideRouter(routes),
    provideClientHydration(
      withEventReplay()
    ),
    provideHttpClient(
      withFetch()
    ),
    ChatSessionManager,
    AiAssistantService,
    ChatApiService,
  ]
};
