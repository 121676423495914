import { Injectable } from '@angular/core';
import { ChatOpenAI, OpenAIEmbeddings } from '@langchain/openai';
import { environment } from '../../environments/environment';

// @Injectable({
//   providedIn: 'root'
// })
// export class OpenAIService {
//   private chatModel!: ChatOpenAI;
//   private embeddings: OpenAIEmbeddings;


//   constructor() {
//     this.initializeChatModel();

//     this.embeddings = new OpenAIEmbeddings({
//       apiKey: environment.openAiKey.trim(),
//       model: 'text-embedding-ada-002' // Modelo específico para embeddings
//   });
//   }


  
//   private initializeChatModel(temperature: number = 0) {
//     this.chatModel = new ChatOpenAI({
//       openAIApiKey: environment.openAiKey.trim(),
//       temperature: temperature,
//       modelName: 'gpt-3.5-turbo-1106',
//       streaming: false,
//       maxConcurrency: 5,
//       maxRetries: 3,
//       timeout: 10000,
      
//     });
//   }

//   getChatModel(temperature?: number): ChatOpenAI {
//     if (temperature !== undefined) {
//       this.initializeChatModel(temperature);
//     }
//     return this.chatModel;
//   }

//   getEmbeddings(): OpenAIEmbeddings {
//     return this.embeddings;
// }
// }

@Injectable({
    providedIn: 'root'
})
export class OpenAIService {
    private readonly DEFAULT_TEMPERATURE = 0;
    private readonly MODEL_CONFIG = {
        chat: 'gpt-3.5-turbo-1106',
        embeddings: 'text-embedding-3-small' // Modelo mais recente de embeddings
    };

    private chatModel!: ChatOpenAI;
    private embeddings: OpenAIEmbeddings;

    constructor() {
        this.validateApiKey();
        try {
            this.initializeChatModel();
            this.embeddings = new OpenAIEmbeddings({
                openAIApiKey: environment.openAiKey.trim(),
                modelName: this.MODEL_CONFIG.embeddings,
                dimensions: 1536
            });
        } catch (error) {
            console.error('Erro na inicialização do OpenAIService:', error);
            throw error;
        }
    }

    private validateApiKey() {
        if (!environment.openAiKey?.trim()) {
            throw new Error('OpenAI API Key não configurada');
        }
    }

    private initializeChatModel(temperature: number = this.DEFAULT_TEMPERATURE) {
        try {
            this.chatModel = new ChatOpenAI({
                openAIApiKey: environment.openAiKey.trim(),
                temperature,
                modelName: this.MODEL_CONFIG.chat,
                maxTokens: 4096, // Defina um limite de tokens explícito
                maxRetries: 3,
            });
        } catch (error) {
            console.error('Erro na inicialização do ChatModel:', error);
            throw error;
        }
    }

    getChatModel(temperature?: number): ChatOpenAI {
        try {
            if (temperature !== undefined) {
                this.initializeChatModel(temperature);
            }
            if (!this.chatModel) {
                throw new Error('ChatModel não foi inicializado corretamente');
            }
            return this.chatModel;
        } catch (error) {
            console.error('Erro ao obter ChatModel:', error);
            throw error;
        }
    }

    getEmbeddings(): OpenAIEmbeddings {
        return this.embeddings;
    }

    isReady(): boolean {
        return !!this.chatModel && !!this.embeddings;
    }
}