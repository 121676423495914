import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChatApiRequest } from '../models/api-chat.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatApiService {
  sendMessage(text: string, sessionId: string) {
    throw new Error('Method not implemented.');
  }
  constructor(private http: HttpClient) { }

  sendMessageStream(payload: any): Observable<any> {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        messages: payload.messages,
        session_id: payload.session_id
      })
    };

    return new Observable(observer => {
      fetch(`${environment.apiUrl}/assistant/chat`, requestOptions)
        .then(response => {
          if (!response.ok) {
            observer.error(new Error(`Erro na requisição: ${response.status}`));
            return;
          }

          const reader = response.body!.getReader();
          const decoder = new TextDecoder();
          let buffer = ''; // Buffer para acumular dados parciais entre chunks

          function processStream() {
            reader.read().then(({ done, value }) => {
              if (done) {
                // Processar qualquer dado restante no buffer antes de completar
                if (buffer.trim()) {
                  try {
                    const events = buffer.split('\n\n');
                    for (const event of events) {
                      if (event.trim() && event.startsWith('data: ')) {
                        processEvent(event);
                      }
                    }
                  } catch (e) {
                    console.error('Erro ao processar buffer final:', e);
                  }
                }
                observer.complete();
                return;
              }

              // Decodificar o valor e adicionar ao buffer
              const chunk = decoder.decode(value, { stream: true });
              buffer += chunk;

              // Procurar por eventos completos no buffer
              const events = buffer.split('\n\n');

              // O último elemento pode ser incompleto, então mantemos no buffer
              buffer = events.pop() || '';

              // Processar todos os eventos completos
              for (const event of events) {
                if (event.trim()) {
                  processEvent(event);
                }
              }

              processStream();
            }).catch(error => {
              observer.error(error);
            });
          }
          function processEvent(event: string) {
            if (event.startsWith('data: ')) {
              const data = event.substring(6).trim();
              if (data) {
                try {
                  const parsedData = JSON.parse(data);

                  // Processar mensagens no novo formato
                  if (parsedData.type === 'message' && parsedData.content) {
                    observer.next({
                      type: 'text',
                      text: parsedData.content
                    });
                  }
                  // Processar mensagem de conclusão
                  else if (parsedData.type === 'complete') {
                    observer.next({
                      type: 'complete',
                      content: parsedData.content || '',
                    });
                  }
                  // Processar ofertas (caso ainda existam)
                  else if (parsedData.type === 'offers' && parsedData.offers) {
                    observer.next({
                      type: 'offers',
                      offers: parsedData.offers,
                    });
                  }
                  // Processar erros
                  else if (parsedData.type === 'error' && parsedData.content) {
                    observer.next({
                      type: 'error',
                      text: parsedData.content,
                    });
                  }
                  // Manter compatibilidade com o formato antigo (opcional)
                  else if (parsedData.type === 'text' && parsedData.content) {
                    // Verificar se content é uma string que contém JSON
                    if (typeof parsedData.content === 'string' &&
                      (parsedData.content.startsWith('{') || parsedData.content.startsWith('{'))) {
                      try {
                        const innerContent = JSON.parse(parsedData.content);
                        if (innerContent && innerContent.text) {
                          observer.next({
                            type: 'text',
                            text: innerContent.text
                          });
                        } else {
                          observer.next({
                            type: 'text',
                            text: parsedData.content
                          });
                        }
                      } catch (innerError) {
                        observer.next({
                          type: 'text',
                          text: parsedData.content
                        });
                      }
                    } else {
                      observer.next({
                        type: 'text',
                        text: parsedData.content
                      });
                    }
                  }
                  // Para qualquer outro tipo
                  else {
                    observer.next({
                      type: parsedData.type || 'unknown',
                      text: parsedData.content || '',
                    });
                  }
                } catch (e) {
                  console.error('Erro ao processar chunk do stream:', e, 'Raw data:', data);
                }
              }
            }
          } processStream();
        })
        .catch(error => {
          observer.error(error);
        });
    });
  }
}