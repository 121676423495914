import { Injectable } from '@angular/core';
import { ConversationChain } from 'langchain/chains';
import { ConversationSummaryMemory } from 'langchain/memory';
import { VectorStoreRetrieverMemory } from 'langchain/memory';
import { MemoryVectorStore } from "langchain/vectorstores/memory";
import { HuggingFaceInferenceEmbeddings } from '@langchain/community/embeddings/hf';

import { Message } from '../models/message.model';
import { Offer } from '../models/offer.model';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { OpenAIService } from './openai.service';
import { KeywordObject } from '../models/keyword.model';
import { RagService } from './rag.service';
import { CHAT_PROMPTS } from './prompts/chat-prompts.config';
import { ServicoTratamentoErros } from './tratamento-Erros.service';
import { RespostaChat } from '../models/chat.model';
import {
  AgentExecutor,
  createOpenAIFunctionsAgent
} from "langchain/agents";
import { Tool } from 'langchain/tools';
import { environment } from '../../environments/environment';
import { StringOutputParser } from '@langchain/core/output_parsers';

@Injectable({
  providedIn: 'root'
})
export class AiChatService {
  private intencaoChain!: ConversationChain;
  private conversaChain!: ConversationChain;
  private palavrasChaveChain!: ConversationChain;
  private conversationMemory!: ConversationSummaryMemory;
  private productMemory!: VectorStoreRetrieverMemory;
  private historicoDeMensagens: Message[] = [];
  private produtoSubject = new BehaviorSubject<Offer[]>([]);
  produtos$ = this.produtoSubject.asObservable();
  private tools: Tool[] = [];
  private agentExecutor!: AgentExecutor;

  constructor(
    private ragService: RagService,
    private openAIService: OpenAIService,
    private servicoTratamentoErros: ServicoTratamentoErros
  ) {
    this.inicializarLangChain().catch(error =>
      console.error('Erro ao inicializar LangChain:', error)
    );
  }

  private async inicializarLangChain() {
    try {
      const intencaoModel = this.openAIService.getChatModel(0.1);
      const conversaModel = this.openAIService.getChatModel(0.7);
      const palavrasChaveModel = this.openAIService.getChatModel(0.3);
      const outputParser = new StringOutputParser();

      // Configuração da memória de conversação
      this.conversationMemory = new ConversationSummaryMemory({
        llm: this.openAIService.getChatModel(0.3),
        memoryKey: "chat_history",
        returnMessages: true
      });

      // Configuração do vectorStore
      const vectorStore = new MemoryVectorStore(
        new HuggingFaceInferenceEmbeddings({
          model: "sentence-transformers/all-MiniLM-L6-v2",
          apiKey: environment.HUGGINGFACE_API_KEY
        })
      );

      this.productMemory = new VectorStoreRetrieverMemory({
        vectorStoreRetriever: vectorStore.asRetriever(),
        memoryKey: "product_history",
        inputKey: "input",
        outputKey: "output" 
      });

      // Configuração das chains com os novos prompts
      this.intencaoChain = new ConversationChain({
        llm: intencaoModel,
        prompt: CHAT_PROMPTS.intencao,
        outputParser: outputParser,
        memory: this.conversationMemory,
      });

      this.palavrasChaveChain = new ConversationChain({
        llm: palavrasChaveModel,
        prompt: CHAT_PROMPTS.palavrasChave,
        outputParser: outputParser,
        memory: this.conversationMemory
      });

      // Configuração do agente
      const agentChain = new ConversationChain({
        llm: conversaModel,
        prompt: CHAT_PROMPTS.agente,
        outputParser: outputParser,
        memory: this.conversationMemory
      });

      // Configuração das ferramentas
      // this.tools = [
      //   new FiltrarProdutosTool(this.obterTodosOsProdutos()),
      //   new SugestaoPresentesTool(this.obterTodosOsProdutos()),
      //   new InfoProdutoTool(this.obterTodosOsProdutos())
      // ];

      const agent = await createOpenAIFunctionsAgent({
        llm: conversaModel,
        tools: this.tools,
        prompt: CHAT_PROMPTS.agente
      });

      this.agentExecutor = new AgentExecutor({
        agent,
        tools: this.tools,
        memory: this.conversationMemory,
        maxIterations: 3,
        returnIntermediateSteps: true,
        handleParsingErrors: true
      });

    } catch (erro: any) {
      throw this.servicoTratamentoErros.tratarErro(erro, 'AiChatService', 'inicializarLangChain');
    }
  }

  async enviarMensagem(mensagem: string): Promise<RespostaChat> {
    try {
      const chatHistory = await this.conversationMemory.loadMemoryVariables({});
      const productContext = await this.productMemory.loadMemoryVariables({
        inputValues: { input: mensagem }
      });

      // Invocando a chain de intenção com o novo formato
      const respostaIntencao = await this.intencaoChain.invoke({
        input: mensagem,
        chat_history: chatHistory["chat_history"]
      });

      const analiseIntencao = (respostaIntencao['response'] as string).trim().toUpperCase();

      let resposta: RespostaChat;

      if (analiseIntencao === 'SIM') {
        // Invocando a chain de palavras-chave com o novo formato
        const palavrasChaveResponse = await this.palavrasChaveChain.invoke({
          input: mensagem
        });
        
        const objetoPalavraChave: KeywordObject = {
          keywords: JSON.parse(palavrasChaveResponse['response'] as string)
        };
        
        resposta = await this.processarRequisicaoOferta(mensagem, objetoPalavraChave);

        if (resposta.products?.length > 0) {
          await this.productMemory.saveContext(
            { input: mensagem },
            { output: JSON.stringify(resposta.products) }
          );
        }
      } else {
        const respostaAgente = await this.agentExecutor.invoke({
          input: mensagem,
          chat_history: chatHistory["chat_history"],
          product_context: productContext["product_history"],
          agent_scratchpad: ""
        });

        resposta = {
          message: {
            type: 'generic',
            text: respostaAgente["output"] as string,
            sender: 'assistant',
            headerMessage: respostaAgente["output"] as string,
            footerMessage: '💬 Qualquer coisa é só chamar'
          },
          products: []  // Movido para fora do objeto message
        };
      }

      // Salvando o contexto da conversa
      await this.conversationMemory.saveContext(
        { input: mensagem },
        { output: typeof resposta.message === 'string' ? 
            resposta.message : 
            resposta.message.headerMessage 
        }
      );

      this.atualizarHistorico(mensagem, resposta);
      return resposta;

    } catch (error) {
      console.error('Erro no processamento:', error);
      return this.gerarRespostaErro();
    }
  }

  private async extrairPalavrasChave(message: string): Promise<KeywordObject> {    try {
      const resposta = await this.palavrasChaveChain.call({
        input: message,
        chat_history: []  // Inicializa chat_history vazio para palavras-chave
      });

      console.log('Resposta do keywordChain:', resposta);

      const arrayPalavrasChave = JSON.parse(resposta['response']);

      return {
        keywords: arrayPalavrasChave,
      };
    } catch (error) {
      throw new Error('Não foi possível processar sua busca. Por favor, tente novamente com termos mais específicos.');
    }
  }

  private async processarRequisicaoOferta(mensagem: string, objetoPalavraChave: KeywordObject): Promise<RespostaChat> {
    const resultado = await firstValueFrom(
      this.ragService.obterOfertasRelevantes(mensagem, objetoPalavraChave)
    );

    if (resultado.offers.length === 0) {
      return {
        message: {
          type: 'generic',
          text: '🔍 Não encontrei ofertas específicas para sua busca.',
          sender: 'assistant',
          headerMessage: 'Eita 😓',
          footerMessage: 'Que tal tentar com outros termos? 💭'
        },
        products: []
      };
    }

    const ofertasOrdenadas = this.ordenarOfertas(resultado.offers);

    return {
      message: {
        type: resultado.isGenericSearch ? 'suggestions' : 'offers',
        text: resultado.isGenericSearch ? 
          '✨ Separei algumas sugestões para você:' : 
          '🎉 Encontrei estas ofertas incríveis!',
        sender: 'assistant',
        headerMessage: resultado.isGenericSearch ?
          '✨ Encontrei coisa boa por aqui em 😎' :
          '🎉 O que vc acha ?',
        footerMessage: '💡 Posso ajudar com mais detalhes sobre algum produto?'
      },
      products: ofertasOrdenadas  // Movido para fora do objeto message
    };
  }
  private atualizarHistorico(mensagem: string, resposta: RespostaChat) {
    const mensagemTexto = typeof resposta.message === 'string'
      ? resposta.message





      : resposta.message.headerMessage ?? '';
    this.historicoDeMensagens = [
      ...this.historicoDeMensagens,
      { text: mensagem, sender: 'user' },
      { text: mensagemTexto, sender: 'ai' }
    ];
  }

  private gerarRespostaErro(): RespostaChat {
    return {
      message: {
        type: 'generic',
        text: 'tive um pequeno problema.',
        sender: 'assistant',
        headerMessage: '👋 Desculpe',
        footerMessage: '✨ Pode tentar novamente?'
      },
      products: []
    };
  }

  obterHistoricoMensagens(): Message[] {
    return this.historicoDeMensagens;
  }

  definirHistoricoMensagens(messages: Message[]) {
    this.historicoDeMensagens = messages;
    this.atualizarProdutosDasMensagens();
  }

  private obterTodosOsProdutos(): Offer[] {
    if (typeof window !== 'undefined') {
      const produtosSalvos = localStorage.getItem('chatProducts');
      if (produtosSalvos) {
        const produtos = JSON.parse(produtosSalvos);
        this.produtoSubject.next(produtos);
        return produtos;
      }
    }
    return this.produtoSubject.getValue();
  }

  private atualizarProdutosDasMensagens() {
    let todosOsProdutos: Offer[] = [];
    this.historicoDeMensagens.forEach(message => {
      if ('products' in message && Array.isArray(message.products) && message.products.length > 0) {
        todosOsProdutos.push(...message.products);
      }
    });
  }

  private ordenarOfertas(ofertas: Offer[]): Offer[] {
    return ofertas.sort((a, b) => {
      if (a.discount > 0 && b.discount === 0) return -1;
      if (a.discount === 0 && b.discount > 0) return 1;
      if (a.discount > 0 && b.discount > 0) {
        return b.discount - a.discount;
      }
      return a.price - b.price;
    });
  }

  definirProdutos(products: Offer[]) {
    this.produtoSubject.next(products);
    if (typeof window !== 'undefined') {
      localStorage.setItem('chatProducts', JSON.stringify(products));
    }
  }

  private limparProdutos() {
    this.produtoSubject.next([]);
    if (typeof window !== 'undefined') {
      localStorage.removeItem('chatProducts');
    }
  }
}