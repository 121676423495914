import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { Message } from '../models/message.model';
import { Offer } from '../models/offer.model';
import { ChatApiService } from './chat-api.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class AiAssistantService {
  private sessionId: string = '';
  private messageHistory: Message[] = [];
  private produtosSubject = new BehaviorSubject<Offer[]>([]);
  public produtos$ = this.produtosSubject.asObservable();

  constructor(
    private chatApiService: ChatApiService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      // Executar apenas no navegador
      this.sessionId = this.getOrCreateSessionId();
      this.loadMessageHistory();
    } else {
      // No servidor, use um ID temporário ou vazio
      this.sessionId = uuidv4();
    }
  }

  private getOrCreateSessionId(): string {
    if (isPlatformBrowser(this.platformId)) {
      const storedId = localStorage.getItem('session_id');
      if (storedId) return storedId;
      
      const newId = uuidv4();
      localStorage.setItem('session_id', newId);
      return newId;
    }
    return uuidv4(); // Fallback
  }

  private loadMessageHistory(): void {
    if (isPlatformBrowser(this.platformId)) {
      const storedHistory = localStorage.getItem('message_history');
      if (storedHistory) {
        this.messageHistory = JSON.parse(storedHistory);
      }
    }
  }

  private saveMessageHistory(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('message_history', JSON.stringify(this.messageHistory));
    }
  }

  obterHistoricoMensagens(): Message[] {
    return [...this.messageHistory];
  }

  enviarMensagem(mensagem: string): Observable<any> {
    // Adicionar a mensagem do usuário ao histórico
    this.messageHistory.push({
      text: mensagem,
      sender: 'user'
    });
    this.saveMessageHistory();

    // Preparar payload para a API
    const payload = {
      messages: this.messageHistory.map(msg => ({
        role: msg.sender === 'user' ? 'user' : 'assistant',
        content: msg.text
      })),
      session_id: this.sessionId
    };

    // Usar o stream method do chatApiService
    return this.chatApiService.sendMessageStream(payload);
  }

  definirProdutos(produtos: Offer[]): void {
    this.produtosSubject.next(produtos);
  }
}